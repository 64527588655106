import { Auth, Metadata, Package, Plan } from '@workspaces/types'
import { UserPermissions } from '../permissions.manager.type'
import ResolverBasic from './resolver-basic'

/**
 * 📢 Admin role represents someone from CCF-Admin agency
 */
class PermissionResolverFI extends ResolverBasic implements UserPermissions {
  private getPackageFromPlan(plan: Plan.Plan): Package.PlanPackage {
    if (plan.package === undefined || plan.package === null) {
      throw new Error('Plan package is not defined')
    }

    if (typeof plan.package === 'string') {
      throw new Error('Plan package is not deserialized')
    }

    return plan.package
  }

  canUploadCustomPOIs(): boolean {
    return true
  }

  canEditAnyCustomPOIGroup(): boolean {
    return false
  }

  permitComments(planOwner: string): boolean {
    return planOwner === this.getName() || Auth.Role.Admin === this.getRole()
  }

  canSavePlan(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)
    if (!planPackage.packages || planPackage.packages.length === 0) {
      return false
    }
    const planId = plan.id
    if (!planId) {
      return true
    }

    const planOwner = plan.created_by
    return this.getName() === planOwner || this.getRole() === Auth.Role.Admin
  }

  canSavePlanAsPublic(): boolean {
    return true
  }

  static DeleteablePlanStates: Package.State[] = [
    Package.State.Draft,
    Package.State.Expired,
    Package.State.Cancelled,
  ]

  canDeletePlan(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)
    const planOwner = plan.created_by
    const userCanDelete =
      this.getName() === planOwner || this.getRole() === Auth.Role.Admin
    const isPlanDeletable = PermissionResolverFI.DeleteablePlanStates.includes(
      planPackage.state,
    )
    return userCanDelete && isPlanDeletable
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canDeleteCustomGeoboundary(owner: string): boolean {
    return false
  }

  canDeleteCustomPOIGroup(owner: string): boolean {
    return this.isOwnerOrAdmin(owner)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canUpdateCustomPOIGroup(owner: string): boolean {
    return false
  }

  canAdminEditPlan(plan: Plan.Plan): boolean {
    if (!this.isAdmin()) {
      return false
    }
    if (!plan.package) {
      return false
    }
    const planPackage = this.getPackageFromPlan(plan)
    if (plan.id !== undefined && planPackage.state !== Package.State.Draft) {
      return true
    }
    return false
  }

  canEditPlanPrice(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)
    if (!planPackage) {
      return false
    }

    if (planPackage.state === Package.State.Draft) {
      return true
    }

    if (plan.created_by !== this.getName() && this.isAdmin()) {
      return plan.editing || false
    }
    return false
  }

  canBookPlanPackage(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)

    if (this.isAnonymous()) {
      return false
    }

    const arePackagesSet =
      planPackage.packages && planPackage.packages.length > 0

    return (
      !this.isAdmin() &&
      planPackage.state === Package.State.Draft &&
      arePackagesSet
    )
  }

  canConfirmBookingPlanPackageByAdmin(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)
    return this.isAdmin() && planPackage.state === Package.State.BookingRequest
  }

  canConfirmBookingPlanPackageByAgency(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)

    if (
      !this.isAdmin() &&
      planPackage.state === Package.State.BookingConfirmedByAdmin
    ) {
      const expirationTime = planPackage.expirationForAgencyBookingConfirmation
      if (expirationTime === undefined) {
        console.warn(
          'Trying to decide is plan can be confirmed without expiration booking timestamp',
        )
        return false
      }

      const nowInMillis = new Date().getTime()
      const isExpired = nowInMillis - expirationTime.getTime() >= 0
      return !isExpired
    }

    return false
  }

  canFinalizeConfirmBookingPlanPackageByAdmin(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)

    return (
      this.isAdmin() &&
      planPackage.state === Package.State.BookingConfirmedByAgency
    )
  }

  canCancelPlanPackageByAdmin(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)

    // if (!this.isAdmin() && plan.package.state === Package.State.Booked) {
    //   const expirationTime = plan.package.expirationForAgencyCancellation
    //   if (expirationTime === undefined) {
    //     console.warn(
    //       'Trying to decide is plan can be confirmed without an expiraction cancellation  timestamp',
    //     )
    //     return false
    //   }
    //   const nowInMillis = new Date().getTime()
    //   const isExpired = nowInMillis - expirationTime.getTime() > 0
    //   return !isExpired
    // }
    const expectedState =
      planPackage.state === Package.State.BookingConfirmedByAdmin ||
      planPackage.state === Package.State.Booked

    return this.isAdmin() && expectedState
  }

  canShowWidgetUploadAssets(): boolean {
    return this.isAdmin()
  }

  static EditableDatesStates: Package.State[] = [
    Package.State.Draft,
    Package.State.BookingRequest,
    Package.State.ReBooking,
  ]

  canEditPlanPackageDates(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)
    if (this.isAdmin()) {
      return (
        plan.editing ||
        plan.created_by === this.getName() ||
        !plan.id ||
        planPackage.state === Package.State.Draft
      )
    }

    return PermissionResolverFI.EditableDatesStates.includes(planPackage.state)
  }

  canEditPlanPackagePrice(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)
    if (planPackage.packages && planPackage.packages.length > 0) {
      return false
    }
    if (planPackage.state === Package.State.Draft) {
      return true
    }
    if (this.isAdmin() && plan.editing) {
      return true
    }

    return false
  }

  canEditPlanPackageCustomPanels(plan: Plan.Plan): boolean {
    if (!this.isAdmin()) {
      return false
    }
    if (!plan.id) {
      return true
    }
    if (plan.editing) {
      return true
    }
    const planPackage = this.getPackageFromPlan(plan)
    if (
      plan.created_by === this.getName() &&
      planPackage.state === Package.State.Draft
    ) {
      return true
    }
    return false
  }

  canEditPlanPackagePanelTypes(plan: Plan.Plan): boolean {
    const planPackage = this.getPackageFromPlan(plan)
    if (planPackage.packages && planPackage.packages.length > 0) {
      return false
    }

    if (planPackage.state === Package.State.Draft) {
      return true
    }
    if (this.isAdmin() && plan.editing) {
      return true
    }

    return false
  }

  canEditPlanPackagePackages(plan: Plan.Plan): boolean {
    if (this.isAdmin()) {
      // ADMIN
      if (plan.editing) {
        return true
      }
      if (!plan.id) {
        return true
      }
      const planPackage = this.getPackageFromPlan(plan)
      if (planPackage.state === Package.State.Draft) {
        return true
      }
      return plan.created_by === this.getName()
    } else {
      // USER
      const planPackage = this.getPackageFromPlan(plan)
      return planPackage.state === Package.State.Draft
    }
  }

  canShowStats(meta: Metadata.AppMetadata): boolean {
    return meta.features.stats.enable && this.isAdmin()
  }
}

export default PermissionResolverFI
