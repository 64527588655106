import Vue from 'vue'
import Router from 'vue-router'
import authService from './api/authService'
import { getInstance as getAuth0ServiceIntance } from '@/auth/index'
import i18n from '@/i18n'
import { Md5 } from 'ts-md5'
import { isCustomGeoboundaryDatasetEnabled } from '@/plan/metadata/metadata.helper'
import { getAppMetadata } from './plan/metadata/metadata'
import {
  StorageDestination,
  setValueToStorage,
  getValueFromStorage,
  removeValueFromStorage,
} from '@/cache/storage.helper'
import { SessionStorageKeys } from './constants'
import SharedPlanPasswordManager from '@/helpers/sharedPlanPassword.helper'

Vue.use(Router)

const Root = () => import('./views/root/Root.vue')
const Login = () => import('./views/login/Login.vue')
const Dashboard = () => import('./views/dashboard/Dashboard.vue')
const Plan = () => import('./views/plan/Plan.vue')
const PanelInsights = () => import('./views/panel-insights/PanelInsights.vue')
const DeliveriesTable = () =>
  import('./views/deliveries-table/DeliveriesTable.vue')
const ManagePois = () => import('./views/manage-pois/ManagePois.vue')
const ManagePoisDetails = () =>
  import('./views/manage-pois-details/ManagePoisDetails.vue')
const ManageCustomGeoboundaries = () =>
  import('./views/manage-custom-geoboundaries/ManageCustomGeoboundaries.vue')
const ManageCustomGeoboundariesDetails = () =>
  import(
    './views/manage-custom-geoboundary-details/ManageCustomGeoboundaryDetails.vue'
  )
const SharedPlanFeedback = () =>
  import('./views/shared-plan-feedback/SharedPlanFeedback.vue')

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/shared-plan-feedback',
      name: 'shared-plan-feedback',
      component: SharedPlanFeedback,
      meta: { skipAuth: true },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { skipAuth: true },
    },
    {
      path: '/deliveries',
      name: 'deliveries-table',
      component: DeliveriesTable,
    },
    {
      path: '/',
      component: Root,
      children: [
        {
          path: '',
          name: 'dashboard',
          component: Dashboard,
        },
        {
          path: 'pub',
          name: 'public',
          props: true,
          component: Plan,
          meta: { toolbar: true },
        },
        {
          path: 'plan/:id?',
          name: 'plan',
          props: true,
          component: Plan,
          meta: { toolbar: true },
        },
        {
          path: 'insights/:type/:id',
          name: 'panel-insights',
          component: PanelInsights,
          props: true,
          meta: { toolbar: true, miniToolbar: true },
        },
        {
          path: 'insights',
          name: 'share-insights',
          component: PanelInsights,
          props: false,
          meta: { toolbar: true },
        },
        {
          path: 'plan',
          name: 'share-plan',
          component: Plan,
          props: true,
          meta: { toolbar: true },
        },
        {
          path: 'manage-pois',
          name: 'manage-pois',
          component: ManagePois,
        },
        {
          path: 'manage-pois/:id',
          name: 'manage-pois-details',
          component: ManagePoisDetails,
          props: true,
          meta: { toolbar: true, miniToolbar: true },
        },
        {
          path: 'manage-custom-geoboundaries',
          name: 'manage-custom-geoboundaries',
          component: ManageCustomGeoboundaries,
        },
        {
          path: 'manage-custom-geoboundary-details',
          name: 'manage-custom-geoboundary-details-new',
          component: ManageCustomGeoboundariesDetails,
          meta: { toolbar: true },
        },
        {
          path: 'manage-custom-geoboundary-details/:id',
          name: 'manage-custom-geoboundary-details',
          component: ManageCustomGeoboundariesDetails,
          props: true,
          meta: { toolbar: true },
        },
      ],
    },
  ],
})

const needsPasswordRequest = (to) => {
  const paramsTo = to.query
  const planId = paramsTo.planId
  const sharedPasswordManager = SharedPlanPasswordManager.getInstance()
  const passwordIsRequired = sharedPasswordManager.isPasswordRequired()

  if (passwordIsRequired && sessionStorage.getItem(planId) === null) {
    const passwordValue = window.prompt(i18n.t('ENTER PASSWORD'))
    const passwordValueHashed = passwordValue
      ? Md5.hashStr(passwordValue)
      : undefined
    sharedPasswordManager.setPassword(passwordValueHashed)
  }
}

const isUrlForSharedPlan = (to) => {
  const params = to.query
  const isPathValid = to.path === '/plan' || to.path === '/insights'
  const sharedPlan = params.shared === 'true' && isPathValid
  return sharedPlan
}

const isSharedPlanByUrlParams = () => {
  const url = window.location.href
  const queryParams = new URLSearchParams(url)
  const sharedPlan = queryParams.get('shared')
  return sharedPlan === 'true'
}

const isSharedPlanActive = () => {
  const sharedPasswordManager = SharedPlanPasswordManager.getInstance()
  const isActive = sharedPasswordManager.isActive()
  return isActive
}

router.beforeEach((to, from, next) => {
  setValueToStorage(
    StorageDestination.SessionStorage,
    SessionStorageKeys.PreviousRoute,
    JSON.stringify(from.path),
  )
  const sharedPlan = isUrlForSharedPlan(to)
  const auth0Service = getAuth0ServiceIntance()
  let needsAuth = !to.matched.some((m) => m.meta.skipAuth)
  let needsToReAuthenticate = false
  // console.debug('🟨🟨🟨 Router: going to: ', to.path)
  if (sharedPlan) {
    // console.debug('🟨🟨🟨 Router: going to (Shared plan)')
    needsAuth = false
    if (isSharedPlanActive()) {
      needsPasswordRequest(to)
    } else {
      const origin = window.location.origin
      next(
        router.push({
          name: 'shared-plan-feedback',
          query: { task: 'error', link: origin + to.fullPath },
        }),
      )
    }
  }
  const hasAuth = auth0Service?.isUserAuthenticated || authService.isLoggedIn()
  const scopes = authService.getScopes()
  const isSharedPlan = isSharedPlanByUrlParams()
  const redirection = { name: 'login', query: { ...to.query, next: to.path } }

  if (
    to.name === 'manage-custom-geoboundaries' ||
    to.name === 'manage-custom-geoboundary-details' ||
    to.name === 'manage-custom-geoboundary-details-new'
  ) {
    const allowed = isCustomGeoboundaryDatasetEnabled(getAppMetadata())
    if (!allowed) {
      next(redirection)
      return
    }
  }

  needsToReAuthenticate = needsAuth && !hasAuth
  if (needsToReAuthenticate) {
    setValueToStorage(
      StorageDestination.LocalStorage,
      SessionStorageKeys.PlanUrl,
      to.path,
    )
    next('/login?next=' + to.path)
  } else if (needsAuth) {
    if (to.name === 'advance-options') {
      const isAdmin = authService.isAdmin()
      if (!isAdmin) {
        next(redirection)
        return
      }
    }
    if (hasAuth) {
      if (isSharedPlan) {
        const id = String(to.params.planid || to.params.id || to.params.planId)
        const type = to.params.type
        const allowedPlanIds = scopes
          .map((s) => s.split(':'))
          .filter((s) => s[0] === 'plan')
          .map((s) => s[1])

        const validPlanId = allowedPlanIds.indexOf(id) !== -1
        let allowed = false
        if (to.name === 'public') {
          allowed = true
        }
        if (to.name === 'plan' && validPlanId) {
          allowed = true
        }
        if (to.name === 'panel-insights') {
          if (type === 'panel') {
            allowed = true
          }
          if (type === 'plan') {
            allowed = validPlanId
          }
        }

        if (allowed) {
          next()
        } else {
          next(redirection)
        }
      } else {
        const urlOfPlanToredirect = getValueFromStorage(
          StorageDestination.LocalStorage,
          SessionStorageKeys.PlanUrl,
        )
        if (urlOfPlanToredirect) {
          removeValueFromStorage(
            StorageDestination.LocalStorage,
            SessionStorageKeys.PlanUrl,
          )
          next(urlOfPlanToredirect)
        } else {
          next()
        }
      }
    } else {
      next(redirection)
    }
  } else {
    next()
  }
})

export default router
